import axios from 'axios';

// const axiosServices = axios.create({ baseURL: process.env.REACT_APP_API_URL || 'http://localhost:3010/' });
// const axiosServices = axios.create({ baseURL: 'http://localhost:8800/api/' });
// Dev server API
// const axiosServices = axios.create({ baseURL: 'https://node.gokarobaar.com/api/' });
// Production server API
const axiosServices = axios.create({ baseURL: 'https://api.inspectnbuy.com/api/' });
// const axiosServices = axios.create({ baseURL: 'https://dev-api.inspectnbuy.com/api/' });

// ==============================|| AXIOS - FOR MOCK SERVICES ||============================== //
axiosServices.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('serviceToken');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// axiosServices.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     if (error.response.status === 401 && !window.location.href.includes('/login')) {
//       window.location.pathname = '/login';
//     }
//     return Promise.reject((error.response && error.response.data) || 'Wrong Services');
//   }
// );

export default axiosServices;
